import React from 'react';
import Details from './Details';
import pdfimageok from '../styles/images/pdfimageok.png'
import pdfimageno from '../styles/images/pdfimageno.png'
import Paginado from '../components/Paginado'
import { useState } from 'react';
import Loader from './Loader';

export default function DetailsMenu({ listing, sha256, encodedHash, pdfFiles, loaded, jsonLength }) {

  function orderArr(array) {
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < array.length-1; j++) {
        let left = parseInt(array[j][10].slice(4) + array[j][10].slice(2,4) + array[j][10].slice(0,2))
        let right = parseInt(array[j+1][10].slice(4) + array[j+1][10].slice(2,4) + array[j+1][10].slice(0,2))
        if (left < right) {
          let temp = array[j];
          array[j] = array[j + 1];
          array[j + 1] = temp;
        }
      }
    }
    return array;
  }


  const [orderedList, setOrderedList] = useState("");


let orderedArr = encodedHash?.length && window.location.pathname.length > 1 && jsonLength == encodedHash.length ? orderArr(encodedHash) : [];

!orderedList?.length && orderedArr?.length && setOrderedList(orderedArr);



let newListing =  (orderedList.length && window.location.pathname.length > 1 ? orderedList.map(o => {
  return listing.filter( hash => hash == o[0])[0];
}) : listing);

let newSha = orderedList.length && window.location.pathname.length > 1 ? newListing : sha256;


  const [currentPage, setCurrentPage] = useState(1);
  const [pdfsPerPage, setPdfsPerPage] = useState(5);
  const lastPdf = pdfsPerPage * currentPage;
  const firstPdf = lastPdf - pdfsPerPage;
  let currentPdfs = newListing?.slice(firstPdf, lastPdf);
  const paginado = (page) => {
    setCurrentPage(page);
  }


  //<img className="loading" alt='loading' id='loading' src={loading} />

  let currentDetail = encodedHash?.filter(elem => elem[0] == sha256)[0];


  let initialArr;


  const [showState, setShowState] = useState(false);
  const [showDetail, setShowDetail] = useState(window.location.pathname.slice(1).length > 0 && window.location.pathname.slice(1).length < 10 ? [false] : [true]);

  const setDetailState = (index) => {
    let arr = showDetail;
    arr.map((e, arrIndex) => {
      if (e && index !== arrIndex) {
        arr[arrIndex] = false;
      }
    })
    if (arr[index]) {
      arr[index] = false;
      setShowDetail(arr);
    } else {
      arr[index] = true;
      setShowDetail(arr);
    }
    if (showState) {
      setShowState(false);
    } else {
      setShowState(true);
    }
  }


  (sha256?.length && !showDetail?.length) && ((initialArr = sha256?.map(e => false)) && (setShowDetail(initialArr)));



  return (
    <div className={window.location.pathname.slice(1).length < 10 && window.location.pathname.slice(1).length > 0 ? 'homeUpdatesContainer homeOperatorContainer' : 'homeUpdatesContainer'}>
      {
        !currentPdfs?.length || !(newListing?.length) || (pdfFiles?.length ? (loaded.length !== pdfFiles.length) : !loaded.length) ? <Loader /> : currentPdfs?.map((file, index) => (
          <div key={file.name ? file.name + (index * currentPage) : newListing[index] + (index * currentPage)} >
            <div className='homeUpdate' >
              <div className='divImage'>
                {(encodedHash.length && encodedHash.filter(elem => elem[0] == newSha[index + (pdfsPerPage * (currentPage - 1))]).length) ? (<img className='pdfImage' src={pdfimageok} alt="preview" />) : <img className='pdfImage' src={pdfimageno} alt="preview" />}
              </div>
              <div className='fileDiv'>
                <h4 className='fileH4'>{file.name ? (file.name.length > 30 ? file.name.slice(0, 30) + "..." : file.name) : currentDetail?.length && currentDetail[1].length ? currentDetail[1] : (encodedHash?.length && newListing?.length ? encodedHash?.filter(elem => elem[0] == newSha[index + (pdfsPerPage * (currentPage - 1))])[0][1] : "File hash details")}</h4>
                <span className='fileSpan'>{file.size && ((file.size / 1000000).toFixed(2) + 'MB')}</span>
              </div>
              {encodedHash.length && encodedHash.filter(elem => elem[0] == newSha[index + (pdfsPerPage * (currentPage - 1))]).length ? <div className='showMoreDiv'><span className='showMoreSpan'>Show More</span><div className='downArrowDiv' onClick={() => setDetailState(index + (pdfsPerPage * (currentPage - 1)))}></div></div> : <div className='showMoreDiv'><span className='showMoreSpan2'>Show More</span><div className='errorDiv' onClick={() => setDetailState(index + (pdfsPerPage * (currentPage - 1)))}></div></div>}
            </div>
            <div>
              {encodedHash.length && encodedHash.filter(elem => elem[0] == newSha[index + (pdfsPerPage * (currentPage - 1))]).length ? <div>{showDetail[index + (pdfsPerPage * (currentPage - 1))] && <Details index={index + (pdfsPerPage * (currentPage - 1))} file={pdfFiles[index + (pdfsPerPage * (currentPage - 1))]} sha256={newSha[index + (pdfsPerPage * (currentPage - 1))]} />}</div> : <div>{showDetail[index + (pdfsPerPage * (currentPage - 1))] && <Details index={index + (pdfsPerPage * (currentPage - 1))} file={pdfFiles[index + (pdfsPerPage * (currentPage - 1))]} error='error' />}</div>}
            </div>
          </div>

        ))

      }
      <div>{((listing.length > 5) && sha256?.length == loaded?.length) && <Paginado pdfsPerPage={pdfsPerPage} listing={listing.length} paginado={paginado} currentPage={currentPage} />}</div>
    </div>
  )
}
