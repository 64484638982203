import React from "react";
import '../styles/Paginado.css'

export default function Paginado ({pdfsPerPage, listing, paginado, currentPage}){
    let totalPages = [];
    for(let i = 1 ; i <= Math.ceil(listing/pdfsPerPage) ; i++){
        totalPages.push(i);
    }

    return (
        <div className="paginadoDivMain">
            {
                totalPages.length && totalPages.map(p => {
                    return (
                        <div key={p*currentPage} className={currentPage == p ? 'paginadoDiv paginadoRed' : 'paginadoDiv'}>
                            <a className={currentPage == p ? 'paginadoA paginadoRed' : 'paginadoA'} onClick={() => paginado(p)}>{p}</a>                            
                        </div>
                    )
                })
            }
        </div>
    )
}