
const initialState = {
    encodedHash: [],
    loaded: []
}

function rootReducer(state = initialState, action) {
    switch(action.type){
        case 'GET_ENCODEDHASH': 
            if(!action.payload){
                return {
                    ...state,
                    loaded: [...state.loaded, true]
                }
            }
            return {
                ...state,
                encodedHash: [...state.encodedHash, action.payload],                
                loaded: [...state.loaded, true]
            }            
            
        default: return state            
    }
}

export default rootReducer;