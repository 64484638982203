import React from 'react';
import '../styles/Home.css'
import flechita from '../styles/images/flechita.png'
import logo from '../styles/images/logo.png'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { getEncodedHash } from '../actions';
import DetailsMenu from './DetailsMenu';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import operator from '../styles/images/operator.jpeg'
import Loader from './Loader';
import searchImg from '../styles/images/search.png'

export default function Home() {
  const encodedHash = useSelector(state => state.encodedHash);
  const loaded = useSelector((state) => state.loaded);
  const dispatch = useDispatch();
  const [sha256, setSha256] = useState([]);
  const navigate = useNavigate();


  function readbinaryfile(file) {
    return new Promise((resolve, reject) => {
      var fr = new FileReader();
      fr.onload = () => {
        resolve(fr.result)
      };
      fr.readAsArrayBuffer(file);
    });
  }

  function Uint8ArrayToHexString(ui8array) {
    var hexstring = '',
      h;
    for (var i = 0; i < ui8array.length; i++) {
      h = ui8array[i].toString(16);
      if (h.length == 1) {
        h = '0' + h;
      }
      hexstring += h;
    }
    var p = Math.pow(2, Math.ceil(Math.log2(hexstring.length)));
    hexstring = hexstring.padStart(p, '0');
    return hexstring;
  }

  function loadSha256(target) {
    let res = readbinaryfile(target)
      .then((res) => {
        let result = new Uint8Array(res);
        return window.crypto.subtle.digest('SHA-256', result);
      })
      .then(function (result) {
        result = new Uint8Array(result);
        let resulthex = Uint8ArrayToHexString(result);
        return resulthex;
      });
    return res;
  }

  const [pdfFiles, setPdfFiles] = useState([]);


  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "application/pdf": [".pdf"] },
    onDrop: (acceptedFiles) => {
      let shaResult = [];
      let filesArr = [];
      let orderFiles = [];
      acceptedFiles.map((file) => {
        setWebState("state 3");
        loadSha256(file).then((res) => {
          shaResult.push(res);
          dispatch(getEncodedHash(res));
          filesArr.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            }));
          orderFiles.push(file);
        })
      });
      setSha256(shaResult);
      setFiles(filesArr);
      setPdfFiles(orderFiles);
    }
  })
  const [webState, setWebState] = useState("state 1");

  const [loadedStates, setLoadedStates] = useState(false);

  const [listing, setListing] = useState("");
  ((loaded.length == files.length) && listing !== files) && setListing(files);

  const [jsonLength, setJsonLength] = useState("");

  useEffect(async () => {
    let pathname = window.location.pathname.slice(1);
    let json;
    if (((window.location.pathname.length > 1) && webState !== 'state 3')) {
      setWebState('state 3');
      if ((pathname.length < 64) && (parseInt(pathname).toString().length == pathname.length)) {
        let person = {
          Person: pathname
        }
        json = await axios.post("https://bvdb.revo.network/bvspadata", person);
        if (!json.data.length) {
          handleButton();
        }
        setJsonLength(json.data.length);
        let arr = [];
        let arr2 = [];
        json.data.map((h, i) => {
          dispatch(getEncodedHash(h.fileHash)).then(() => {
            if (i == json.data.length - 1) setLoadedStates(true);
          });
          arr.push(h.fileHash);
          arr2.push(false);
        })
        setPdfFiles(arr2);
        setFiles(arr);
        setSha256(arr);

      } else if (pathname.length == 64) {
        dispatch(getEncodedHash(pathname));
        setFiles([pathname]);
        setSha256([pathname]);
      } else {
        handleButton();
      }
      setLoadedStates(true);
    }
  }, [])


  const handleButton = () => {
    navigate('/');
    window.location.reload();
  }


  const [ searchInput, setSearchInput ] = useState("");

  const handleSearchByHash = () => {
    if(searchInput.length < 64){
      alert("Enter a File Hash!")
    }else {
      navigate('/' + searchInput)
      window.location.reload();
    }
  }

  const handleSearchByName = async () => {
    let json;
    if(searchInput.length < 8){
      alert("Enter a Certificate Number!")
    }else {      
      let N_Certificato = {
        N_Certificato: searchInput
      }
      json = await axios.post("https://bvdb.revo.network/bvspadata", N_Certificato);
      if (!json.data.length) {
        return alert("Certificate Number not found!");
      }
      setWebState("state 3");
      setJsonLength(json.data.length);
      let arr = [];
      let arr2 = [];
      json.data.map((h, i) => {
        dispatch(getEncodedHash(h.fileHash)).then(() => {
          if (i == json.data.length - 1) setLoadedStates(true);
        });
        arr.push(h.fileHash);
        arr2.push(false);
      })
      setPdfFiles(arr2);
      setFiles(arr);
      setSha256(arr);
    }
  }

  const [windowScreen, setWindowScreen] = useState({ width: window.screen.width, height: window.screen.height });

  (windowScreen.width !== window.screen.width || windowScreen.height !== window.screen.height) && setWindowScreen({ width: window.screen.width, height: window.screen.height });


  /* state 1 = Home -> Upload File
     state 2 = Home -> Search by hash
     state 3 = Details
     state 4 = Home -> Search by name

  */

  return (
    <div className={webState !== "state 3" ? 'homeMainDiv' : 'detailsMainDiv'}>
      <div className='divLogo'>
        <img className='logoDetail' src={logo} />
      </div>
      <div className={webState !== "state 3" ? 'homeMainContainer' : 'detailsMainContainer'} >
        <div className='backgroundDiv'></div>
        <div>
          {webState !== "state 3" ? <div>
            <div style={{color: `#aaa`, fontWeight: `600`}}>Search by</div>
            <div className='stateButton'>
              <div className={webState == "state 1" ? 'stateButtonLeft stateButtonSelected' : 'stateButtonLeft' } onClick={() => setWebState("state 1")}>Uploading File</div>
              <div className={webState == "state 2" ? 'stateButtonRight stateButtonLeft stateButtonSelected' : 'stateButtonRight stateButtonLeft' } onClick={() => setWebState("state 2")}>Hash</div>
              {<div className={webState == "state 4" ? 'stateButtonRight stateButtonSelected' : 'stateButtonRight' } onClick={() => setWebState("state 4")}>Certificate Number</div>}
            </div>
              <span className='titleHome'>Verify Document Authenticity</span>
            </div> :
            <div>{window.location.pathname.slice(1).length > 63 || !window.location.pathname.slice(1).length ? <span className='titleDetails'>Analysis of required PDF files</span> : <div><img src={operator} className='operator' /> <span className='titleDetails titleOperator'>My Virtual Operator Card</span> <h2 className='thumbSpan spanOperator'>My Certificates List</h2> </div>}</div>}
        </div>
        {
          webState == "state 1" ? (<div {...getRootProps()} className='homeDiv'>
            {!isDragActive ?
              <div>
                <div className='homeContainer' >
                  <input className='op0' {...getInputProps()} />
                  <img className='homeArrow' src={flechita} /><br></br>
                </div>
                <h3 className='homeH3'>Drag PDF files, or</h3>
                <div className='divInput'>
                  <button className='homeChooseButton'><input className='op0' {...getInputProps()} />Choose</button>
                </div>
              </div> :
              <div className='dragZone'>
                <h3 className='homeH3'>Drag PDF Files Here</h3>
              </div>}
          </div>) : webState == "state 3" && (window.location.pathname.slice(1).length > 1 && !loadedStates && jsonLength !== encodedHash.length ? <Loader /> : (<DetailsMenu listing={listing} sha256={sha256} encodedHash={encodedHash} pdfFiles={pdfFiles} loaded={loaded} jsonLength={jsonLength} />))
        }{webState == 'state 2' &&
          <div className='homeDiv'>
            <h3 className='homeH3 byHash'>Search by Hash</h3><br></br>
            <div className='searchDiv'>
             <input className='searchInput' placeholder='Enter your hash here..' type="text" onChange={(e) => setSearchInput(e.target.value)} />
             <button className='searchButton' onClick={handleSearchByHash}><img className='searchButtonImg' src={searchImg} /></button>
            </div>
          </div>
        }{webState == 'state 4' &&
        <div className='homeDiv'>
          <h3 className='homeH3 byHash'>Search by Certificate Number</h3><br></br>
          <div className='searchDiv'>
           <input className='searchInput' placeholder='Enter your certificate number here..' type="text" onChange={(e) => setSearchInput(e.target.value)} />
           <button className='searchButton' onClick={handleSearchByName}><img className='searchButtonImg' src={searchImg} /></button>
          </div>
        </div>
      }
        {webState.length > 0 && ((listing?.length > 0) && listing.length == loaded.length) && <button onClick={() => handleButton()} className='backButton'>Back</button>}
      </div>
      <div className='foot'>
        <span className='footH3'>Powered by <b>RevoTechnologies</b></span>
      </div>
    </div>
  );
}
