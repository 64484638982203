import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from "react-pdf";
import pdfPlaceholder from '../styles/images/pdfplaceholder.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Thumb({ file = false, cert, idNum}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

//<embed src={`https://cors-proxy.htmldriven.com/?url=https://bvstorage.revo.network/${idNum}`} type="application/pdf"  className='react-pdf__Page__canvas' />

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return (
    <div className='thumb'>
      {cert && <div className='thumbSpanDiv'><span className='thumbSpan'><b>Certificate<br></br> {cert}</b></span></div>}
      { file ? <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document> : cert ? (window.innerWidth < 700 ? <Document file={`https://api.allorigins.win/raw?url=https://bvstorage.revo.network/${idNum}`} onLoadSuccess={onDocumentLoadSuccess} className='react-pdf__Page__canvas' ><Page pageNumber={pageNumber} /> </Document> : <embed src={`https://bvstorage.revo.network/${idNum}`} type="application/pdf" className='react-pdf__Page__canvas' />) : <img src={pdfPlaceholder} className='react-pdf__Page__canvas' /> /*<Document file={{url: `https://07bvstorage.revo.network/2,1666226a2d28`}} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document> : <embed src={`https://bvstorage.revo.network/${idNum}`} className='react-pdf__Page__canvas' /> )*/  }
      <span className='thumbSpan'>
      {file ? `Page ${pageNumber} of ${numPages}` : ""}
      </span>
    </div>
  );
}
export default Thumb;
