import React from "react";
import '../styles/Details.css'
import { useState } from "react";
import { useSelector } from "react-redux";
import Thumb from "./Thumb";
import fileOk from '../styles/images/fileok.png'
import fileError from '../styles/images/fileerror.png'
import errorBlacklist from '../styles/images/error-blacklist.png'
import axios from "axios";

export default function Details ({ index, file, error, sha256 }) {
    const encodedHash = useSelector((state) => state.encodedHash);

    const details = [
        "File Hash",
        "Certificate",
        "Notarized by",
        "Certification Schema",
        "Assigned to",
        "File URL",
        "Revision",
        "Hash of revision",
        "Valid",
        "Date Release",
        "Date Emission",
        "Expires at",
        "Notarized"
    ]
    function timeConverter(UNIX_timestamp) {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }


    let currentDetail = error?.length ? [] : encodedHash?.filter(elem => elem[0] == sha256)[0];
    const [formattedTime, setFormattedTime] = useState("");
    ( currentDetail.length && !formattedTime.length) && setFormattedTime(timeConverter(currentDetail[12]));

    const [ isInBlacklist, setIsInBlacklist ] = useState(false);

    async function verifyCertificate(target){
        let obj = {
            N_Certificato: target
        }
        let json;
        try {
            json = await axios.post('https://bvdb.revo.network/verifycert', obj);
            setIsInBlacklist(json.data);
        }catch(err){
            setIsInBlacklist(false);
        }
    }
    currentDetail.length && verifyCertificate(currentDetail[1]);

    async function getDbData(target) {
	let json;
            try {
                json = await axios.post('https://bvdb.revo.network/bvspadata', target);
                setTransactionData(json.data[0].sendToAPI);
                 
            }catch (error) {
                json = error.response;         
            }                
//console.log(json);
    }


    function compareDates(certDay, certMonth, certYear) {
        let today = new Date();
        let day = today.getDate();
        let month = today.getMonth() + 1;
        let year = today.getFullYear();

        if(certYear < year){
            return false;
        }else if(certYear == year){
            if(certMonth < month){
                return false;
            }else if(certMonth == month) {
                if(certDay < day){
                    return false;
                }
            }
    }
    return true;
}


    const [transactionData, setTransactionData] = useState("");
    (currentDetail[0]?.length && !transactionData.length) && getDbData({fileHash: currentDetail[0]});

    return (
        <div className="detailsContainer" key={!error?.length ? currentDetail[0] : index}>
            {window.screen.width > 575 && <Thumb file={file} cert={currentDetail?.length && currentDetail[1]?.length ? currentDetail[1] : false} idNum={currentDetail[5]} />}

            <div className="detailsDataMain">
                {!error?.length ? ((window.location.pathname.slice(1).length > 10) && <div className="divCert"><span className="spanCert"> This Certificate is original!</span></div>) : <span></span>}
                {window.screen.width <= 575 && <Thumb file={file} cert={currentDetail?.length && currentDetail[1].length ? currentDetail[1] : false} idNum={currentDetail[5]} />}
                <div className="detailsDataContainer">
                    {
                        !error?.length ?
                            <div className="detailsDiv">
                                <div>
                                    <span className="spanTitle">{details[0]}: </span><span className="hashSpan">{currentDetail[0]}</span>
                                </div>
                                <div className="">
                                    <span className="spanTitle">Transaction Id:</span> {transactionData.length && <a target='_blank' className="fileLink hashSpan" href={`https://mainnet.revo.network/tx/${transactionData}`}>{transactionData}</a>}
                                </div>
                                <div>
                                    <span className="spanTitle">{details[3]}: </span> <span>{currentDetail[3]}</span>
                                </div>
                                <div >
                                    <span className="spanTitle">{details[5]}</span><span>: <a target='_blank' className="fileLink hashSpan" href={`https://bvstorage.revo.network/${currentDetail[5]}`}>Original File</a></span>
                                </div>{ (currentDetail[8] == "true") && currentDetail[6] &&
                                <div className="dataDiv">
                                    <span className="spanTitle">{details[6]} </span> <span>: {currentDetail[6]}</span> 
                                </div>}{ (currentDetail[8] !== "true") && currentDetail[7] &&
                                <div className="">
                                    <span className="spanTitle">{details[7]}: </span>&nbsp;<a target='_blank'  className="fileLink hashSpan" href={`https://bvchecker.revo.network/${currentDetail[7]}`}> {currentDetail[7]}</a>
                                </div>}
                                <div className="dataDiv">
                                    <span className="spanTitle">{details[8]}: </span>{ isInBlacklist ? <div style={{display: `flex`, alignContent: `center`}}><img src={errorBlacklist} className="fileBlacklist" /><span style={{color: `#242222`, fontWeight: `bold`, marginLeft: `5px`}}>CANCELED</span></div> : ((currentDetail[8] !== "true") || !compareDates(currentDetail[11]?.slice(0, 2), currentDetail[11]?.slice(2, 4), currentDetail[11]?.slice(4)) ? <div className="dataDiv"><img className="fileError" src={fileError} />{!compareDates(currentDetail[11]?.slice(0, 2), currentDetail[11]?.slice(2, 4), currentDetail[11]?.slice(4)) ? <span className="expiredSpan">EXPIRED</span> : <span></span>}</div> : <div> <img className="fileOk" src={fileOk}/> </div>)}
                                </div>
                            </div> : <div className="detailsDiv errorDivSpan"> <span className="errorSpan">This file is not recognized as original, contact Bureau Veritas S.p.A. for more info. New Certificates take some time to get notarized. If your certificate is new, please allow up to 24h before checking against blockchain system.</span> </div>
                    }
                    <div className="detailsTableContainer">
                        {!error?.length ?
                            <div className="detailsTable topTable">{
                                !error?.length ? details.slice(9,12).map((det, detIndex) => {

//if(detIndex < details.slice(9).length-1){
                                    return (
                                        <div key={detIndex} className="detailsTableDivs topDivs">{det}</div>
                                    )
//}
                                }) : <div></div>
                            }
                            </div> : <div></div>
                        }
                        {!error?.length ?
                            <div className="detailsTable bottomTable">
                                {details.slice(9,12).map((det, detIndex) => {
//if(detIndex < details.slice(9).length-1){
                                    return (
                                        <div key={detIndex} className="detailsTableDivs bottomDivs">{!(detIndex + 9 < details.length - 1) ? formattedTime : currentDetail[detIndex + 9]?.slice(0, 2) + '/' + currentDetail[detIndex + 9]?.slice(2, 4) + '/' + currentDetail[detIndex + 9]?.slice(4)}</div>
                                    )
//}
                                })
                                }
                            </div> : <div></div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
