import axios from 'axios';

export function getEncodedHash(hash) {
    return async (dispatch) => {
        let hashUrl = encodeURIComponent('"' + hash + '"');
        let str = encodeURIComponent('"string"');
        try {
            let json = await axios('https://api.revo.network/abi-encode/[' + str + ']/['+ hashUrl + ']');
            let json2 = await axios('https://api.revo.network/contract/13a129dc59d7adf553d4a672c95c0f6780d18e00/call?data=ea2bf867' + json.data);
            let json3 = await axios('https://api.revo.network/abi-decode/["string[]"]/["' + json2.data.executionResult.output + '"]');
            
        return dispatch({
            type: 'GET_ENCODEDHASH',
            payload: json3.data[0]
        })
        }catch (error) {
            let json3 = error.response
            
        return dispatch({
            type: 'GET_ENCODEDHASH',
            payload: json3
        })
        }
        
    }
}

